import Image from 'next/image';
import styles from './error.module.css';
import errorImg from '../../public/404/404.png';
import { Button, Container, Htag, HtagVariant } from '../../components';

export default function ErrorPage() {
  return (
    <section className={styles.error}>
      <Container>
        <div className={styles.wrapper}>
          <Htag tag="h2" className={styles.title} variant={HtagVariant.h2}>
            Oops!
          </Htag>
          <p className={styles.text}>Sorry we can’t find that page!</p>
          <Image
            alt="404"
            width={864}
            height={494}
            src={errorImg}
            priority
            unoptimized
            quality={100}
          />
          <Button
            tag="a"
            href="/"
            className={styles.button}
            appearance="primary"
          >
            Home page
          </Button>
        </div>
      </Container>
    </section>
  );
}
