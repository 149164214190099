import Head from 'next/head';
import { Header } from '../../components';
import menu from '../../utils/menu';
import ErrorPage from './error.component';

export default function Error404() {
  return (
    <>
      <Head>
        <title>MAVINX | 404</title>
      </Head>
      <Header appearance="transparent" appearanceLogo="primary" menu={menu} />
      <ErrorPage />
    </>
  );
}
